:root {
  /* Primary Theme Colors */
  --bs-primary: #0d6efd;        /* Default Primary */
  --bs-secondary: #6c757d;      /* Default Secondary */
  --bs-success: #198754;        /* Default Success */
  --bs-info: #0dcaf0;           /* Default Info */
  --bs-warning: #ffc107;        /* Default Warning */
  --bs-danger: #dc3545;         /* Default Danger */
  --bs-light: #f8f9fa;          /* Default Light */
  --bs-dark: #212529;           /* Default Dark */

  /* Grayscale Colors */
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;

  /* Body Text and Background */
  --bs-body-bg: #ffffff;        /* Background color */
  --bs-body-color: #000000;     /* Text color */

  /* Borders */
  --bs-border-color: #dee2e6;   /* Border color */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.footer {
  position: sticky;
  bottom: 0;
  background-color: #f8f9fa;
  text-align: center;
  padding: 10px;
  width: 100%;
  border-top: 1px solid #ddd;
}



.custom-carousel .carousel-indicators li {
  background-color: #333; /* Darker color for contrast */
  width: 12px; /* Larger size */
  height: 12px;
  border-radius: 50%; /* Circle shape */
  border: 2px solid #fff; /* White border for better visibility */
}

.custom-carousel .carousel-indicators .active {
  background-color: #555; /* Slightly lighter for the active indicator */
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    filter: invert(100%); /* This makes dark arrows on a light background */
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5); /* Add a dark background to improve visibility */
    border-radius: 50%;
    padding: 10px;
}